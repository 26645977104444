import React from 'react';
import '../styles/panditjistyle.css';
import { useNavigate } from 'react-router-dom';
import RegisterPanditji from '../components/PanditjiRegister';
import FeaturedServices from './FeaturedServices';
import Testimonial from './Testimonial';
import WhyUs from './WhyUs';
import Howitworks from './Howitworks';

const Home = () => {
  const navigate = useNavigate();

  // Function to navigate to the 'HowWeAreDifferent.js' page
  const handleClick = () => {
    navigate('/howwearedifferent'); // Route to HowWeAreDifferent.js page
  };

  return (
    <div>
      <RegisterPanditji />
      <main>
        <section className="hero">
          <div className="container">
            <h1>Vedic Science at the Heart of Our Rituals: Bringing Depth and Meaning to Every Pooja</h1>
            <p className="glowing-text">Book expert Pandit Ji services online for all your religious ceremonies.</p>
          </div>
        </section>

        {/* Offer banner linking to How We Are Different page */}
        <div className="offer-banner" onClick={handleClick}>
          Check Here!! How We Are Different!!
        </div>

        <WhyUs />

        {/* Scientific Approach Section */}
        <section className="scientific-approach">
          <div className="container">
            <h2>Our Scientific Approach to Pooja</h2>
            <p>
              Our pooja services blend ancient traditions with scientific principles, benefiting you mentally and physically:
            </p>
            <ul>
              <li><strong>Mantra Chanting:</strong> Creates positive vibrations, enhancing focus.</li>
              <li><strong>Fire Ritual (Homa):</strong> Purifies the environment, reducing bacteria and negativity.</li>
              <li><strong>Offerings:</strong> Items like ghee and herbs are chosen for their spiritual and health benefits.</li>
            </ul>
            <button onClick={handleClick} className="learn-more-button">Learn More</button>
          </div>
        </section>

        <FeaturedServices />

        <Howitworks />
        
        <Testimonial />
      </main>
    </div>
  );
};

export default Home;
