import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Login.css'

const Login = () => {
    const [mobile, setMobile] = useState('+91');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    

    const handleSendOtp = async () => {
        if (!mobile.startsWith('+91')) {
            setMessage('Please ensure the mobile number starts with +91.');
            return;
        }

        if (mobile.length !== 13) { // +91 followed by 10 digits
            setMessage('Please enter a valid 10-digit mobile number.');
            return;
        }

        setIsLoading(true);
        setMessage('');
        try {
            const response = await axios.post('https://findmypanditjibackend-d94611cee10f.herokuapp.com/api/send-login-otp/', { mobile_number: mobile });
            if (response.data.success) {
                setIsOtpSent(true);
                // alert("OTP Sent on your mobile number successfully.")
                setMessage("OTP Sent on your email and mobile successfully.")
            }
            else {
                setMessage('Failed to send OTP. Please try again.');
            }
        }  catch (error) {
            if (error.response && error.response.status === 404) {
                setMessage('User not found. Please register first.');
            } else {
                setMessage('Error sending OTP. Please try again.');
            }
            console.error('Error sending OTP:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleVerifyOtp = async () => {
        if (otp.length !== 6) {
            setMessage('Please enter a valid 6-digit OTP.');
            return;
        }
        setIsLoading(true);
        setMessage('');
        try {
            const response = await axios.post('https://findmypanditjibackend-d94611cee10f.herokuapp.com/api/verify-login-otp/', { mobile_number: mobile, otp });
            if (response.data.success) {
                sessionStorage.setItem('access_token', response.data.token);
                sessionStorage.setItem('username', response.data.username);
                sessionStorage.setItem('user_type', response.data.user_type);
                sessionStorage.setItem('mobile_number', mobile)
                sessionStorage.setItem('email',response.data.email)
                axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('access_token')}`;
                setMessage('OTP validated. Redirecting...');
                // navigate('/findMyPanditji'); // Correct placement of navigate
                const userType = response.data.user_type;
            const redirectUrl = userType === 'panditji'
                ? `https://findyourpandit.com/PanditjiDashboard`
                : userType === 'admin'
                ? 'https://findyourpandit.com/findmymaid#'
                : 'https://findyourpandit.com/findmypanditji#';  // Normal user route
            
            window.location.href = redirectUrl;
        } else {
                // alert(response.data.message);
                console.error('Invalid OTP');
                setMessage(response.data.message);
            }
        } catch (error) {
            setMessage('Error verifying OTP. Please try again.');
            console.error('Error verifying OTP:', error);
        } finally {
            setIsLoading(false);
        }
    };

return (
    
        <>
            
            <div className="login-container">
                <div className="login-card">
                    <h1 className="login-title">Login With Your Mobile Number</h1>
                    <input
                        type="text"
                        className="login-input"
                        placeholder="Mobile Number"
                        value={mobile}
                        onChange={(e) => {
                            let value = e.target.value;
                        
                            // Retain +91 and allow only digits (0-9) afterward
                            if (value.startsWith('+91')) {
                              if (/^\+91[0-9]{0,10}$/.test(value)) {
                                setMobile(value);
                              }
                            } else {
                              // If the user tries to delete +91, reset it back
                              setMobile('+91');
                            }
                          }}
                          maxLength="13"
                          inputMode="numeric" // Helps on mobile devices to show numeric keypad
                        />
                    {isOtpSent ? (
                        <>
                            <input
                                type="text"
                                className="login-input"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                            <button className="login-button" onClick={handleVerifyOtp} disabled={isLoading}>
                            {isLoading ? 'Verifying...' : 'Verify OTP'}
                            </button>
                        </>
                    ) : (
                        <button className="login-button" onClick={handleSendOtp} disabled={isLoading}>
                            {isLoading ? 'Sending...' : 'Send OTP'}
                        </button>
                    )}
                    {message && <p className="login-message">{message}</p>}
                </div>
            </div>
            
        </>
    );
};

export default Login;
